<template>
    <ui-card class="profile-card" :title="$t('Profile')" :description="$t('Update your personal account information.')">
        <template #actions v-if="account">
            <avatar :name="account.first_name" :last-name="account.last_name" size="xs" />
        </template>
        <div v-if="loading" class="text-center py-5">
            <b-spinner />
        </div>
        <template v-else>
            <b-alert class="my-0" show variant="info">
                <b>{{ $t('Note') }}:</b>
                {{ $t('If you update your phone number or email, we will send you a verification code to verify that the contact method provided exists.') }}
            </b-alert>
            <form @submit.prevent="handleSubmit">
                <div>
                    <p class="profile-card__subtitle">
                        <span class="fas fa-user"></span>
                        {{ $t('Basic information') }}
                    </p>
                    <div class="mb-4">
                        <ui-input
                            id="firstName"
                            name="firstName"
                            type="text"
                            placeholder="John"
                            :label="$t('Name')"
                            :value="account.first_name"
                            :disabled="true"
                        />
                    </div>
                    <div class="mb-4">
                        <ui-input
                            id="lastName"
                            name="lastName"
                            type="text"
                            placeholder="Doe"
                            :label="$t('Last name')"
                            :value="account.last_name"
                            :disabled="true"
                        />
                    </div>    
                    <p class="profile-card__subtitle">
                        <span class="fas fa-map-marker-alt"></span>
                        {{ $t('Contact information') }}
                    </p>
                    <div class="mb-4">
                        <ui-input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="email@example.com"
                            :label="$t('Email')"
                            v-model="formData.email"
                            :disabled="false"
                            :field="v$.formData.email"
                        />
                    </div>
                    <div class="mb-4">
                        <ui-phone-input
                            ref="phoneInput"
                            id="phone"
                            v-model="formData.phone"
                            name="phone"
                            type="text"
                            disable-dropdown
                            placeholder="55 1254 5678"
                            :auto-default-country="!selectedCountry"
                            :default-country="selectedCountry"
                            :country="selectedCountry"
                            :label="$t('Phone')"
                            :field="v$.formData.phone"
                        />
                    </div>
                    <div class="d-flex justify-content-end">
                        <ui-button
                            type="submit"
                            variant="accent"
                            class="mb-0"
                            :loading="updatingProfile"
                        >
                            {{ $t('Save') }}
                        </ui-button>
                    </div>
                </div>
            </form>
            <vue-recaptcha
                class="w-100"
                ref="profileRecaptcha"
                size="invisible"
                @verify="updateProfile"
                :sitekey="CONST.CAPTCHACODE"
                :loadRecaptchaScript="true"
            />
        </template>
    </ui-card>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators';

import Avatar from '@/components/ui/Avatar.vue'
import UiCard from '@/components/ui/Card.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import UiInput from '@/components/ui/inputs/Input.vue';
import UiSelect from '@/components/ui/inputs/Select.vue';
import UiPhoneInput from '@/components/ui/inputs/Phone.vue';

import recaptchaUtils from '@/utils/recaptcha.utils';

export default {
    name: 'ProfileCard',
    components: {
        Avatar,
        UiCard,
        UiButton,
        UiInput,
        UiPhoneInput,
        UiSelect,
        VueRecaptcha,
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            account: null,
            countries: [],
            formData: {
                phone: '',
                country: '',
                email: '',
            },
            loading: true,
            showAccountForm: false,
            updatingProfile: false,
            originalData: {},
        };
    },
    computed: {
        selectedCountry() {
            return this.account.country || this.formData.country || '';
        }
    },
    validations() {
        return {
            formData: {
                phone: { required: helpers.withMessage(this.$t('validations.required', { field: this.$t('phone') }), required), },
                email: {
                    required: helpers.withMessage(this.$t('Email required', { field: this.$t('email') }), required),
                    email: helpers.withMessage(this.$t('Email invalid'), email),
                    $autoDirty: true,
                },
            },
        };
    },
    mounted() {
        this.getAccount();
        this.getCountries();
    },
    methods: {
        async handleSubmit() {
            this.v$.formData.$touch();

            if (this.v$.formData.$invalid) {
                return;
            }

            if (!recaptchaUtils.isAvailable()) {
                this.updateProfile('');
                return;
            }

            this.$refs.profileRecaptcha.reset();
            this.$refs.profileRecaptcha.execute();
        },
        async getCountries() {
            this.countries = await this.fnApiGetCountries();
        },
        async getAccount() {
            try {
                this.loading = true;

                const  { data }  = await this.axiosAccount.get('/api/accounts/me');
                const { phone, country, email } = data;

                if (phone) {
                    this.formData.phone = phone;
                }
                if (email) {
                    this.formData.email = email;
                }
                if (country) {
                    this.formData.country = country;
                }
                
                this.account = data;
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        async updateProfile(token) {
            try {
                this.updatingProfile = true;
                const form_data = { ...this.formData };

                if (this.account.country) {
                    delete form_data.country;
                }

                const { data } = await this.axiosAccount.put('/api/accounts/me', { ...form_data, token });

                this.account = data;
                this.$toasted.global.successMessage(this.$t('Account information updated successfully'));
            } catch (error) {
                this.showError(error);
            } finally {
                this.updatingProfile = false;
            }
        }
    },
};
</script>

<style lang="scss">
.profile-card {
    &__subtitle {
        color: $accounts-secondary-300;
        margin-bottom: 16px;
    }
}
</style>
